import { Link, ScrollShadow } from "@nextui-org/react";
import { NEWS_TYPE } from '@/components/helpers/constant.js';
import LoadingCommon from "@/components/shared/loadingCommon";
import { CategoryHelper } from '@/components/helpers/category';
import SharedServiceInstance from "@/services/shared-service";
import SharedService from "@/services/shared-service";
import { useRouter } from "next/navigation";
import { awaitNavigate, showFlower } from "@/utils/common-utils";

var _ = require('lodash');

export default function Notification(notificationsdata) {
    const notifications = notificationsdata.data;
    const currentCategory = CategoryHelper.getCurrentCategory(NEWS_TYPE.NOTIFICATIONS.code);
    const router = useRouter();

    if (!currentCategory) {
        return <></>
    }

    const cateDetailLink = currentCategory.level === 0
        ? `/chuyen-muc/${currentCategory.code}`
        : `/chuyen-de/${currentCategory.code}`;

    return (
      <div className="md:pt-5 md:px-20 px-5 h-full w-full text-center !bg-background/80 dark:bg-background/20 !bg-white/85 !bg-cover !bg-[url('/webp/nha-chung.webp')] !bg-blend-lighten rounded-[5px]">
            <Link href={cateDetailLink}>
                <h1 className="text-2xl font-extrabold pb-5 text-primary">{NEWS_TYPE.NOTIFICATIONS.name.toLocaleUpperCase()}</h1>
            </Link>

            {notifications.total === 0 && <>
                <LoadingCommon />
            </>}

            <ScrollShadow hideScrollBar className="w-full h-[400px]">
                <div className="divide-y-1">
                    {
                        notifications.value.map((item, index) => {
                            return (
                                <div key={item.id} className="text-link w-full py-5 text-left cursor-pointer text-[16px]"
                                    onClick={(e) => {
                                        showFlower(e);
                                        awaitNavigate(router, SharedService.getPostPath(item))
                                    }}
                                >
                                    {item.title} - {SharedServiceInstance.formatISODate(item.created) || ''}
                                </div>
                            );
                        })
                    }
                </div>
            </ScrollShadow>
      </div>
    );
}