'use client'

import Image from "next/image";
import Swipe from "react-easy-swipe";
import React, {useState} from "react";

import {FaAngleDoubleRight} from "react-icons/fa";
import {NEWS_TYPE} from '@/components/helpers/constant.js';
import {Link, ScrollShadow, Tooltip} from "@nextui-org/react";
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import { YouTubeEmbed } from '@next/third-parties/google';
import SharedServiceInstance from "@/services/shared-service";
import { CategoryHelper } from '@/components/helpers/category';

import logo from '../../public/webp/logo-for-web.webp';

var _ = require('lodash');

function SaltIframe(params) {
    return <>
        <div className="w-full h-full" key={params.data.mediaFileEmbedId}>
            <YouTubeEmbed videoid={params.data.mediaFileEmbedId} height={params.data.height} style={`max-width:100%;width:100%;height:100%; background-image: url(${SharedServiceInstance.getOptimizeImage(params.data.photo)});`} />
        </div>
    </>
}

export default function SaltForLife(dataSalt) {
    let saltList = _.filter(dataSalt.data.value, salt => salt?.mediaFileEmbedId);
    saltList = _.slice(saltList, 0, 4);
    const [currentSlide, setCurrentSlide] = useState(0);
    const handleNextSlide = () => {
        const newSlide = (currentSlide === saltList.length - 1) ? 0 : currentSlide + 1;
        setCurrentSlide(newSlide);
    };
    const handlePrevSlide = () => {
        const newSlide = (currentSlide === 0) ? saltList.length - 1 : currentSlide - 1;
        setCurrentSlide(newSlide);
    };
    const classIconsSlide = "absolute m-auto text-2xl sm:text-5xl inset-y-1/2 cursor-pointer text-gray-400 z-20 bg-white ";

    const currentCategory = CategoryHelper.getCurrentCategory(NEWS_TYPE.SALT_FOR_LIFE.code);
    if (!currentCategory) {
        return <></>
    }

    const cateDetailLink = currentCategory.level === 0
        ? `/chuyen-muc/${currentCategory.code}`
        : `/chuyen-de/${currentCategory.code}`;

    return (
        saltList.length > 0 &&
        <div className="mx-auto">
            <div className="sm:grid grid-cols-2 bg-slate-200 text-primary text-center sm:text-left py-2 px-5 rounded-t-[5px]">
                <div className="flex">
                    <div className="logo">
                        <Image className="flex justify-center"
                               src={logo}
                               alt="Logo"
                               width={50}
                               height={50}
                        />
                    </div>

                    <div className="ml-5 my-auto">
                        <p className="text-black">
                            Giáo Phận Phú Cường
                        </p>
                        <h1 className="text-2xl font-bold">
                            {NEWS_TYPE.SALT_FOR_LIFE.name.toLocaleUpperCase()}
                        </h1>
                    </div>
                </div>

                <div className="text-right py-2 my-auto">
                    <Link
                        href={cateDetailLink}
                        className="sm:text-xl font-bold"
                    >
                        Xem thêm <FaAngleDoubleRight className="text-1xl ml-1"/>
                    </Link>
                </div>
            </div>

            <div className="relative">
                <AiOutlineLeft onClick={handlePrevSlide} className={classIconsSlide + 'left-0 ml-3'}/>

                {/* Iframe Slide */}
                {dataSalt.data.total > 0 && <>
                    <div className="w-full h-[250px] sm:h-[750px] flex overflow-hidden relative m-auto">
                        <Swipe
                            onSwipeLeft={handleNextSlide}
                            onSwipeRight={handlePrevSlide}
                            className="relative z-10 w-full h-full"
                        >
                            {saltList.map((salt, index) => {
                                if (index === currentSlide) {
                                    let payloadDefault = {
                                        width: '100%',
                                        mediaFileEmbedId: salt.mediaFileEmbedId,
                                        embedTitle: salt.title,
                                        photo: salt.photo
                                    }
                                    return <div className="w-full h-full" key={index}>
                                        <div className="w-full h-full hidden sm:block">
                                            <SaltIframe data={{...payloadDefault, ...{height: '750'}}}/>
                                        </div>
                                        <div className="sm:hidden">
                                            <SaltIframe data={{...payloadDefault, ...{height: '250'}}}/>
                                        </div>
                                    </div>
                                }
                            })}
                        </Swipe>
                    </div>
                </>}

                <AiOutlineRight onClick={handleNextSlide} className={classIconsSlide + 'right-0 mr-3'}/>

                <div className="absolute bottom-0 p-2 z-20 w-full rounded-b-[5px]">
                    <div className="flex flex-grow gap-2 items-center justify-center">
                        {saltList.map((_, index) => {
                            return (
                                <div
                                    className={
                                        index === currentSlide
                                            ? "h-2 w-2 bg-gray-300  rounded-full mx-2 mb-2 cursor-pointer"
                                            : "h-2 w-2 bg-gray-700 rounded-full mx-2 mb-2 cursor-pointer"
                                    }
                                    key={index}
                                    onClick={() => {
                                        setCurrentSlide(index);
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* Iframe mini list */}
            <ScrollShadow hideScrollBar className="w-full h-[300px] sm:h-full">
                <div className="grid lg:flex bg-primary text-white">
                    {saltList.map((salt, index) => {
                        const borderQueue = index % 2 ? 'border-y-3 sm:border-y-0' : 'sm:border-x-3';
                        const currentBlock = currentSlide !== index ? ' backdrop-opacity-10 backdrop-invert bg-white/30 ' : ' ';

                        return <>
                            <div
                                className={`mx-auto lg:w-1/4 border-solid flex drop-shadow-lg` + currentBlock + borderQueue}
                                key={salt.id} onClick={() => {
                                setCurrentSlide(index);
                            }}
                            >
                                <div className="w-3/6 px-2 py-2">
                                    <Tooltip showArrow={true} content={salt.title}>
                                        <p className="font-bold text-xl line-clamp-1">{salt.title}</p>
                                    </Tooltip>

                                    <p className="text-sm pt-2 italic">
                                        <span
                                            className="text-green-600"> {_.head(salt.categories).name} </span>/ {salt?.authorName}
                                    </p>

                                    <div className="py-3 text-sm pr-2">
                                        <Tooltip showArrow={true} content={salt?.metaDescription}>
                                            <p className="line-clamp-2">{salt?.metaDescription}</p>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="w-3/6 my-auto pointer-events-none">
                                    <SaltIframe data={{width: '100%', mediaFileEmbedId: salt.mediaFileEmbedId, photo: salt.photo}}/>
                                </div>
                            </div>
                        </>
                    })}
                </div>
            </ScrollShadow>
        </div>
    )
}
