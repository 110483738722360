import {Image} from "@nextui-org/react";
import {GlobalSettings} from "@/configuration/global-settings";
import SharedServiceInstance from "@/services/shared-service";
import {Carousel, IconButton} from "@material-tailwind/react";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import NextImage from "next/image";
import SharedService from "@/services/shared-service";
import { awaitNavigate, showFlower } from "@/utils/common-utils";
import { useRouter } from "next/navigation";

export default function PostView({data, code}) {
    const sharedService = SharedServiceInstance;
    const router = useRouter();

    const first = data[0];
    if (first) {
        return (
            <div>
                <div className="md:grid md:grid-cols-7 p-5 md:gap-10 hidden">
                    <div
                        className="col-span-full lg:col-span-3 self-center">
                        <div className="relative flex flex-col h-full cursor-pointer"
                            onClick={(e) => {
                                showFlower(e);
                                awaitNavigate(router, SharedService.getPostPath(first))
                            }}
                        >
                            <Image
                                alt={first.title}
                                removeWrapper={true}
                                className="rounded-none lg:w-[100%] lg:h-[100%] max-h-[218px] max-w-[384px]"
                                src={sharedService.isValidUrl(first.photo) ? `${sharedService.getOptimizeImage(first.photo, 'main-post-view')}` : first.photo ? `${GlobalSettings.Settings.Server}/${first.photo}` : "/webp/chanh-toa.webp"}
                                as={NextImage}
                                width={384}
                                height={214}
                                sizes="(max-width: 768px) 100vw,
                              (max-width: 1200px) 50vw,
                              33vw"/>
                            <div className="text-news-type font-medium py-2 self-start">
                                <p>{sharedService.formatISODate(first.created)}</p>
                            </div>
                            <div>
                                <h1 className="text-news py-1 text-xl font-bold line-clamp-1">{first.title}</h1>
                            </div>
                            <div>
                                <p className="text-news py-1 line-clamp-3">{first.metaDescription}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-full lg:col-span-4">
                        <div className="md:grid md:grid-rows-3 md:grid-flow-row md:auto-rows-fr md:gap-y-5 h-full">
                            {
                                data.map((item, index) => {
                                    if (index > 0 && index <= 3) {
                                        return (
                                            <div
                                                className="h-full cursor-pointer"
                                                key={index}
                                                onClick={(e) => {
                                                    showFlower(e);
                                                    awaitNavigate(router, SharedService.getPostPath(item))
                                                }}
                                            >
                                                <div
                                                    className="border-none bg-background/60 dark:bg-default-100/50 h-full items-center">
                                                    <div
                                                        className="border-none bg-background/60 dark:bg-default-100/50 h-full items-center">
                                                        <div
                                                            className="relative flex gap-6 md:gap-4 items-center">
                                                            <Image
                                                                alt={item.title}
                                                                className="rounded-none min-w-[160px] min-h-[90px] max-w-[160px] max-h-[90px]"
                                                                src={sharedService.isValidUrl(item.photo) ? `${sharedService.getOptimizeImage(item.photo, 'small-post-view')}` : item.photo ? `${GlobalSettings.Settings.Server}/${item.photo}` : "/webp/chanh-toa.webp"}
                                                                as={NextImage}
                                                                width={384}
                                                                height={214}
                                                                sizes="(max-width: 768px) 100vw,
                                                                  (max-width: 1200px) 50vw,
                                                                  33vw"/>
                                                            <div
                                                                className="flex flex-col h-full justify-center">
                                                                <div
                                                                    className="flex justify-between items-start text-news">
                                                                    <div className="flex flex-col gap-0 items-start">
                                                                        <h1 className="text-news-type font-medium">{sharedService.formatISODate(item.created)}</h1>
                                                                        <h2 className="text-news text-large font-medium mt-2 line-clamp-1">{item.title}</h2>
                                                                        <h3 className="text-news mt-2 line-clamp-2">{item.metaDescription}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
                {/* Mobile layout: start */}
                <div className="md:hidden">
                    <Carousel
                        loop={true}
                        autoplay={true}
                        autoplayDelay={5000}
                        className="rounded-none"
                        prevArrow={({handlePrev}) => (
                            <IconButton
                                aria-label="button-prev"
                                variant="text"
                                size="md"
                                onClick={handlePrev}
                                className="rounded-full bg-gray-600/40 !absolute top-1/3 left-0 -translate-y-2/4 z-50"
                            >
                                <FaAngleLeft className="fill-white text-3xl"/>
                            </IconButton>
                        )}
                        nextArrow={({handleNext}) => (
                            <IconButton
                                aria-label="button-next"
                                variant="text"
                                size="md"
                                onClick={handleNext}
                                className="rounded-full bg-gray-600/40 !absolute top-1/3 right-0 -translate-y-2/4 z-50"
                            >
                                <FaAngleRight className="fill-white text-3xl"/>
                            </IconButton>
                        )}
                        navigation={({setActiveIndex, activeIndex, length}) => (
                            <div className="hidden absolute bottom-4 left-2/4 z-50 -translate-x-2/4 gap-2">
                                {new Array(length).fill("").map((_, i) => (
                                    <span
                                        key={i}
                                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                            activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                        }`}
                                        onClick={() => setActiveIndex(i)}
                                    />
                                ))}
                            </div>
                        )}
                    >
                        {
                            data.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            className="items-center flex flex-col h-full text-[#444444] cursor-pointer"
                                            onClick={(e) => {
                                                showFlower(e);
                                                awaitNavigate(router, SharedService.getPostPath(item))
                                            }}
                                        >
                                            <div className="w-full h-60 max-w-sm">
                                                <Image
                                                    alt={item.title}
                                                    removeWrapper={true}
                                                    className="rounded-none h-full w-full"
                                                    src={sharedService.isValidUrl(item.photo) ? `${sharedService.getOptimizeImage(item.photo, 'main-post-view')}` : `${GlobalSettings.Settings.Server}/${item.photo}`}
                                                    as={NextImage}
                                                    width={100}
                                                    height={100}
                                                    sizes="(max-width: 768px) 100vw,
                                                      (max-width: 1200px) 50vw,
                                                      33vw"/>
                                            </div>
                                            <div className="px-5 py-2 self-start">
                                                <p>{sharedService.formatISODate(item.created)}</p>
                                            </div>
                                            <div>
                                                <h1 className="px-5 py-1 text-xl font-bold line-clamp-1">{item.title}</h1>
                                            </div>
                                            <div>
                                                <p className="px-5 py-1 line-clamp-3">{item.metaDescription}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Carousel>
                </div>
                {/* Mobile layout: end */}
            </div>

        )
    }
}