import {Link, ScrollShadow} from "@nextui-org/react";
import { NEWS_TYPE } from '@/components/helpers/constant.js';

var _ = require('lodash');
import SharedServiceInstance from "@/services/shared-service";
import SharedService from "@/services/shared-service";
import { useRouter } from "next/navigation";
import { awaitNavigate, showFlower } from "@/utils/common-utils";

export default function MostViewPost({data}) {
    const router = useRouter()
    return (
        <div className="pt-5 md:px-20 px-5 !bg-background/80 dark:bg-background/20 !bg-white/85 !bg-cover !bg-[url('/webp/chanh-toa.webp')] !bg-blend-lighten h-full rounded-[5px]">
            <h1 className="text-2xl font-bold mb-5 text-primary text-center">{NEWS_TYPE.MOST_VIEW.name.toLocaleUpperCase()}</h1>

            <ScrollShadow hideScrollBar className="w-full h-[400px]">
                <div className="divide-y-1">
                    {
                        data.value.map((item, index) => {
                            if (index > 0 && index <= 10) {
                                return (
                                    <div key={item.id} className="!text-link w-full py-5 text-left cursor-pointer text-[16px]"
                                        onClick={(e) => {
                                            showFlower(e);
                                            awaitNavigate(router, SharedService.getPostPath(item))
                                        }}
                                    >
                                        {item.title} - {SharedServiceInstance.formatISODate(item.created) || ''}
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </ScrollShadow>

        </div>
    );
}