import { useEffect, useState } from "react";

export async function getStaticProps() {
    return {
        props: {}, // No special props needed for the embed
    };
}

const TikTokEmbed = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true); // Mark that the component is running on the client side
        const script = document.createElement("script");
        script.src = "https://www.tiktok.com/embed.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (!isClient) {
        return null;
    }

    return (
        <blockquote
            className="tiktok-embed"
            cite="https://www.tiktok.com/@gocua.gppc"
            data-unique-id="gocua.gppc"
            data-embed-type="creator"
            style={{ maxWidth: "780px", minWidth: "288px" }}
        >
            <section>
                <a
                    target="_blank"
                    href="https://www.tiktok.com/@gocua.gppc?refer=creator_embed"
                    rel="noopener noreferrer"
                >
                    @gocua.gppc
                </a>
            </section>
        </blockquote>
    );
};

export default TikTokEmbed;
